import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import { NextPage } from 'next'
import axios from 'axios';
// import { AppProps } from "next/app";
import HomePageContainer from '@container/HomePage/HomePageErrorBoundary'
import { isEmpty, path, setCookie } from '@utils/genralUtils'
import { getMobileHpBanners } from '@utils/homepage'
import { BBD_MOBILE_JSON_SERVER } from '@constants/flights/paths'
import { PAGE_TITLE, isFkEnv } from '@root/configs/Whitelabel'
import { goToHome } from '@utils/Navigation'
import { HREFLANG } from '@constants/index'
import { getLanguageMeta } from "@ui/utils"
//TODO: It's update will fix with next code refactor deployment in which we have updated th pageProps in app.tsx
// TODO: We need to remove this destructure in that case
const HomePage: NextPage = (props: any) => {
    const { props: { pageProps = {} } = {} } = props || {}

    useEffect(() => {
        if (isFkEnv) {
            goToHome({ isReloadRequired: false })
        }

        if (path(['cookie'], document) && typeof document.cookie != 'undefined') {
            setCookie('widthValue', window.innerWidth, 1)
        }
    }, [])

    return (
        <>
            <Head>
                <title>{PAGE_TITLE}</title>
                <meta
                    name="description"
                    content="Flight Booking & Hotel Booking made easy✔️Get amazing deals on International & Domestic Flight Tickets & choose from 5 lac+ Hotels. Get last minute hot deals."
                />
                <meta
                    name="keywords"
                    content="cheap air tickets, flight bookings, flight tickets, cheapest airline tickets, flight status, cheap flights, lowest airfares, airline tickets, flight booking, domestic flights, international flights, lowest air tickets, cheap airline tickets, airfare deals, discount airfare tickets, cheap airfares, discount flight tickets, flight deals, booking flight, flight reservations, Book a flight, Cheapest flight, flight tickets reservation, Airline ticket booking, Book a flight reservation, Flight Ticket Reservations, Airline tickets, Book cheap flights, Flight tickets prices, Cheapest airline tickets, Flight ticket prices, booking tickets, Cheapest flight reservations, Cheapest airline booking"
                ></meta>
                <link rel="canonical" href={`${pageProps.canonicalUrl}`} />
                <>
                    {
                        HREFLANG && Object.keys(HREFLANG).map((key) => (
                            key === "x-default" ? <link key={key} rel="alternate" hrefLang={key} href={`${pageProps.canonicalUrl}`} /> : <link key={key} rel="alternate" hrefLang={key} href={HREFLANG[key]} />
                        ))
                    }
                </>
            </Head>
            {!isFkEnv ? (
                <div className="bg-white homePage" style={{ backgroundColor: '#fff' }}>
                    <HomePageContainer
                        error={pageProps.error}
                        bannerList={pageProps.bannerList}
                        query={pageProps.query}
                        page={pageProps.page}
                        widthValue={pageProps.widthValue}
                        fsHistoryMobile={pageProps.fsHistoryMobile}
                        localeObject={pageProps.localeObject}

                    />
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export async function getServerSideProps(context: any) {

    let bannerList = {}
    let data = []
    let widthValue = null
    let isMobileApp = false
    const { query, req } = context || {}
    const userAgent = req.headers['user-agent'] || ''
    const isGooglebot = /bot|googlebot|crawler|spider|robot|crawling/i.test(userAgent)

    const defaultWidth = isGooglebot ? 412 : 390
    widthValue = Number(req.cookies?.widthValue) || defaultWidth
    const fsHistoryMobile = req.cookies?.fsHistoryMobile && JSON.parse(req.cookies?.fsHistoryMobile) || {
        travellers: { adults: 1, children: 0, infants: 0 },
        flightClass: 'Economy'
    }
    let error = ''
    let domain = 'https://www.cleartrip.ae' // Default domain
    let canonicalUrl = domain // Default canonical URL
    let localeObject = {}
    try {
        // Get domain and construct canonical URL
        const host = req.headers['host']
        const path = req.url || '/'
        domain = `https://${host}`
        canonicalUrl = `${domain}${path}`
        localeObject = getLanguageMeta(req.headers['host'])
        const res = await axios.get(BBD_MOBILE_JSON_SERVER(localeObject?.locale?.toLowerCase()), {
            headers: {
                'Cache-Control': 'no-cache',  // Instructs the browser and intermediate caches to not cache the response
                'Pragma': 'no-cache',         // Older HTTP/1.0 fallback
                'Expires': '0'                // Ensures no caching in HTTP/1.0
            }
        });
        bannerList = res.data;

        // data = getMobileHpBanners(bannerList)
    } catch (e) {
        console.log(e)

        error = 'SERVER ERROR'
    }
    return {
        props: {
            error,
            query,
            bannerList,
            page: 'HOME',
            widthValue,
            canonicalUrl,
            fsHistoryMobile,
            localeObject
        }
    }
    // return {
    //     props: {
    //         error,
    //         bannerList:data
    //     },
    //     // Next.js will attempt to re-generate the page:
    //     // - When a request comes in
    //     // - At most once every 10 seconds
    //     revalidate: 10, // In seconds
    // }
}

export default HomePage
